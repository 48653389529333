@media(min-width:992px) {
	.arrow-menu ,
	#nav-icon1 {display: none !important}
	.sub-menu  {display: none} 
}
@media(max-width:1366px) {
	.amenities_txt {
		left: 50px;
	}
	.amenities_row:nth-child(even) .amenities_txt {
		right: 50px;
	}
	.hero-sec.hero-in h1 {font-size: 90px;}
	.hero-menu ul li {font-size: 14px;}
	.green-pattern .cli-arrow {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 46px;
		padding-bottom: 10px;
	}
	.leader-sec {
		margin: 0 -5px;
	}
	.leader-sec .leader_items {padding-bottom: 55px;}
	.leader-sec .leader_items .designation{
		display: inline-block;
	}
	.grid-wrap {
 		padding-left: 20px;
 		padding-right: 20px;
 	}

}
@media(max-width:1199px) {
	.summer-sec {
		padding: 110px 0 65px;
	}
	.btn_sty .cli-arrow ,
	.green-pattern .cli-arrow {
		font-size: 36px;
	}
	.btn_sty .cli-arrow {
		min-width: 627px;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    padding-bottom: 10px;
	}
	.main-header ul  {
		margin: 0 -13px;
		li {
			font-size: 13px;
			padding: 0 13px;
		}
	}
	.intro-sec .intro-txt {
	    padding-right: 15px;
	}
	.hero-menu {background-size: cover; background-position: center top;}
	h1 {
		font-size: 72px;
	}
	.hero-sec .hero-text h1  {margin-bottom: 20px;}
	.hero-sec .hero-text p {font-size: 24px;}
	.amenities_txt{padding: 25px} 
	h3{font-size: 30px;}
	.review .review_text p {font-size: 27px;}
	.adventure_sec .green-pattern .cli-arrow {
		font-size: 40px; 
		min-width: inherit;
		display: flex;
		    text-align: center;
    align-items: center;
    justify-content: center;
	}
	.f_left {
		flex-wrap: wrap;
	}
	.social-icon {
		display: flex;
	}

	.hero-sec.hero-in img {
	    min-height: 480px;
	}
	.hero-sec.hero-in h1 {
	    font-size: 82px;
	}
	 .hero-sec.hero-in h6 {
	    font-size: 25px;
	}
	.home-logo{
		img {
			max-width: 200px;
		}
	}
	.hero-menu {height: 110px;}
	.hero-menu ul li {
	    font-size: 13px;
	    letter-spacing: 1px;
	        padding: 0 15px;
	} 
	.hero-sec {
	    padding-bottom: 65px;
	}

	
	.leader-sec .leader_items .leader_img {
		max-width: 90%;
		margin: 0 auto 25px;
	}
	.leader-sec .leader_items {
		padding-bottom: 60px;
	}
	.leader-sec .leader_items .designation {
	    margin-bottom: 25px;
	} 
	.staff-leadership h3 {
		font-size: 40px;
		margin-bottom: 35px;
	}
	.staff-leadership {padding-top: 35px;}
	.star_bg {padding-bottom: 60px;}
	 .grid-wrap .items h3 { 
	 	font-size: 20px;
	 }
}
@media(max-width:991px) {
	.logo + span {display: none}
	.main-header{
		padding-right: 0;
		height: auto;
		.container {
		    height: 48px;
		}
	}
	.logo img {
	    max-width: 176px;
	}
	.main-menu{
		display: none;
		.home-logo {
			display: none;
		}
	}
	.top-header {
		height: 50px;
	}
	.top-header:before {display: none}

	#nav-icon1 {
	    width: 34px;
	    height: 19px;
	    transform: rotate(0);
	    transition: .5s ease-in-out;
	    cursor: pointer;
	    position: relative;
	    padding: 0;
	}
	#nav-icon1 span {
	    display: block;
	    position: absolute;
	    height: 3px;
	    width: 100%;
	    background: #1a5632;
	    border-radius: 0;
	    opacity: 1;
	    transform: rotate(0);
	    transition: .25s ease-in-out
	}
	#nav-icon1 span:nth-child(1) {
	    top: 0
	}
	#nav-icon1 span:nth-child(2) {
	    top: 8px;
	    right: 0
	}
	#nav-icon1 span:nth-child(3) {
	    top: 16px;
	    right: 0
	}
	#nav-icon1.open span:nth-child(1) {
	    top: 8px;
	    transform: rotate(135deg)
	}
	#nav-icon1.open span:nth-child(2) {
	    opacity: 0;
	    left: -60px;
	    width: 100%
	}
	#nav-icon1.open span:nth-child(3) {
	    top: 8px;
	    width: 100%;
	    right: auto;
	    transform: rotate(-135deg)
	}

	.intro-sec {
		background-position: center;
		.container {
			padding-bottom: 0;
		}
	}
	.intro-sec .intro-img {
		position: static;
		max-width: 70%;
		margin: 0 auto;
	}
	.amenities_txt {
		bottom: 35px;
		width: 360px;
		p {font-size: 1rem}
	}
	.amenities_row img {
	    min-height: 550px;
	}
	.review {padding: 60px 0}
	.green-pattern .cli-arrow ,
	.adventure_sec .green-pattern .cli-arrow {
		font-size: 28px;
		height: 70px; padding-bottom: 5px;
		bottom: -35px;		
    padding: 0 20px 10px;

	}
	h1 {
		font-size: 56px;
	}
	.hero-sec .hero-text p {
		font-size: 21px;
	}
	.btn-def {font-size: 23px;}
	.hero-sec {padding-bottom: 45px;}
	.hero-menu {height: 85px;}
	.hero-sec>img {min-height: 540px;}
	.footer {
		padding-top: 0;
		.container {
			flex-wrap: wrap;
			justify-content: center !important;
		}
	}
	.f_left  {
		order: 2;
	}
	.footer .f_left {padding-top: 0}
	.f_right  {
		order: 1;
		flex-wrap: wrap;
		.silver-cliff-ico {
			margin: 0;
			order: 1;
			margin: 0 auto;
			img {
				position: relative;
				top: -45px
			}
		}
		.social-icon {
			margin-top: -25px;
			width: 100%;
			max-width: 100%;
			order: 2;
			justify-content: center;
		}
	}
	.footer .f_left {
		padding-top: 15px;
		justify-content: center;
		p {
			margin: 0 0 10px;
		}
	}
	.main-menu ,
	.main-header ul.main-menu {
		display: none;
		position: absolute;
		top: 100%;
		background: #d6d1ca;
		width: 100%;
		left: 0;
		margin: 0;
		z-index: -1;
		padding:50px 0 10px;
		li {
			padding: 0;
			height: auto;
			a {
				padding: 10px;
			}
		}
	}

	.hero-sec.hero-in h1 {
	    font-size: 62px;
	}
	.hero-sec.hero-in img {
	    min-height: 420px;
	}
	.hero-menu ul {display: none !important}

	.sub-menu {
		display: none;
	}
	.intro-sec:before {
		height: 15px;
	}
	h4{font-size: 26px;}

	.grid-wrap .items h3 {
		font-size: 18px;
		min-width: 280px;
 	}
 	.grid-wrap .items {
		padding-left: 25px;
		padding-right: 25px;/*
		padding-bottom: 60px;*/
 	}

 	.grid-wrap {
 		    margin-left: -25px;
    margin-right: -25px;
 		padding-top: 60px;
 	}
 	.grid-wrap .items .item-img {margin-bottom: 35px;}
 	.leader-sec .leader_items .cli-arrow {
 		font-size: 19px;
 		display: flex;
 		align-items: center;
 		justify-content: center;
 		padding-bottom: 4px;
	    max-width: 320px;
 	}
 	.leader-sec .leader_items {padding-bottom: 30px}
 	.leader-sec .leader_items h4 { 
 		font-size: 27px;
 	}
 	.grid-wrap .items .item-img:before {
 		left: -15px;
 		top: -15px;
 	}
 	.grid-wrap .items:nth-child(even) .item-img:before {
 		left: 15px;
 		top: 15px;
 	}
 	.arrow-menu {
	    display: block;
	    position: absolute;
	    right: 0;
	    width: 36px;
	    height: 36px;
	    top: 2px;
	    transition: all .3s;
	    &:before{
	  	    content: "\f105";
  	        font-size: 22px;
		    font-family: FontAwesome;
		    color: #000;
		    display: flex;
		    align-items: center;
		    justify-content: center;;
	    }
 	}
 	.menu-item-has-children {
		position: relative;
		flex-wrap: wrap;
		.sub-menu {
			margin: 0;
			padding-left: 15px;
			width: 100%;
			max-width: 100%;

 		}
 		&.show{
 			.arrow-menu { 
 				transform: rotate(90deg)
 			}
 		}

 	}
 	 .sec_abs{
        top: -155px;
    }

}

@media(max-width:767px) { 
	h1 {
		font-size: 48px;
	}
	.hero-sec>img {
	    min-height: 460px;
	}
	.hero-sec .hero-text p {font-size: 21px;}
	.intro-sec .container {padding-top: 40px;}
	.amenities_txt p {
		margin-bottom: 15px;
	}
	.review {padding: 40px 0}
	.review .review_text p {
	    font-size: 20px;
	}
	.review .review_text {
	    min-height: 260px;
    	background-size: auto 100%;
	}
	.green-pattern .cli-arrow  ,
	.btn_sty .cli-arrow ,
	.adventure_sec .green-pattern .cli-arrow {
	    font-size: 22px;
	    padding: 0 20px;
	    min-width: 290px;
	        white-space: nowrap;
	}
	.adventure_sec .green-pattern {
    	height: 90px;
    }
    .footer .f_left {
    	font-size: 15px;
    	ul {
    		margin: 0 -14px;
    		li {
    			padding: 0 14px;
    		}
    	}
    }
    .footer .f_left p {font-size: 16px;}
    #wrapper {font-size: 16px;}
    .hero-sec.hero-in h1 {
	    font-size: 52px;
	}
	.hero-sec.hero-in h6 {
		font-size: 21px;
	}
	.container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    	padding-left: .9rem;
	    padding-right: .9rem;
	}
	h4{font-size: 24px;}
	.inner-intro {
	    padding: 30px 0 70px;
	}
	.inner-intro {padding-bottom: 45px;}
	.grid-wrap .items {
 		max-width: 100%;
 		width: 100%;
 		padding-left: 15px;
	    padding-right: 15px;
	    padding-bottom: 40px;
	    h3{
    	    font-size: 17px;
		    min-width: 245px;
		    padding: 0 10px 2px;
	    }
 	}
 	.grid-wrap .items .item-img:before {
		left: -10px;
    	top: -10px;
 	}
 	.grid-wrap .items:nth-child(even) .item-img:before {
 		left: 10px;
 		top: 10px;
 	}
 	.grid-wrap .items .btn-arrow {margin-right: 0 !important}
 	.grid-boxes {padding-bottom: 15px;}
 	.hero-sec {padding-bottom: 0 !important}
 	.hero-menu{
 		display: none !important;
 	}
 	.hero-sec .hero-text {top: 60%}

 	.leader-sec .leader_items {
 		max-width: 100%;
 		width: 100%;
 	}
 	.leader-sec .leader_items .cli-arrow {
	    font-size: 17px;
    	max-width: 290px;
 	}
 	.staff-leadership h3 {font-size: 27px;}
 	.para_2 p{
 	 	max-width: 100%;
 	 	width: 100%;
 	 	padding: 0;
 	}
 	.btn_sty .cli-arrow {
 		padding: 0 20px;
 		    font-size: 24px;
 	}

 	.grid-wrap .items {
 		.item-img {
 			order: 1 !important
 		}
 		
 		.item-txt{
 			order: 2 !important;
 		}
 	}
 	.groupgrid .grid-wrap .items .item-img {
 		    max-width: 400px;
    margin: 0 auto 35px !important;
 	}
 	.groupgrid .grid-wrap .items:nth-child(even) .items_in .item-img {
 		margin-top: 0;
 	}
 	.grid-wrap .items .items_in:after {display: none}

 	.footer .f_left ul {order: 1; }
 	.footer .f_left p {order: 2; padding-top: 15px;}

 	    .sec_abs{
        top: -100px;
    }
    .hero-menu {display: block !important; height: 68px;}
    .hero-sec .hero-text {top: 50%}
    .intro-sec .intro-img {max-width: 96%; width: 100%}
    .donate-btn {
        font-size: 11px;
            font-weight: 800; letter-spacing: .8px;
                height: 26px;
    line-height: 24px;
    padding: 0 5px;
    }
    .btn-arrow {font-size: 0}
    .main-header ul.main-menu li a, .main-menu li a {padding-left: 15px; width: 100%}
    .footer .f_left p {
	    font-size: 11px; margin-bottom: 0;
	}
	.footer .f_left ul {justify-content: center}
	.footer .f_left ul li {
	    max-width: inherit; width: inherit;
	}
	.social-icon li a {line-height: 1}
	.silver-cliff-ico {max-width: 90px;}
	.intro-sec h4 {margin-bottom: 12px; margin-top: 8px;}
	.inner-intro {
	    padding-bottom: 70px;
	}
}	

@media(max-width:575px) { 
	.btn {    font-size: 18px;
    padding: 9px 20px;
		    height: 40px;
		        min-width: 150px;
		        border-radius: 5px;
	}
	.amenities_row {		
		.container ,
		.amenities_txt {
			position: static !important;
			height: auto;
			max-width: 100%;
			padding: 15px 15px 20px;
			width: 100%;
			.amenities_head {
				background: transparent;
				margin: 0;
			}
			h3 {
				color: #1a5632;
				&:before , &:after {
					display: none;
				}
			}
		}
		.container {padding: 0}
	}
	.amenities_row img {
	    min-height: initial;
	}
	h3{font-size: 24px;}
	.footer .f_left ul li {
		max-width: 100%;
		width: 100%;
		text-align: center;
		&:before{
			display: none;
		}
	}
	.adventure_sec_img  {
		height: 240px;
	}
	h1{font-size: 32px; margin-bottom: 15px;}

	.hero-sec .hero-text p {
	    font-size: 14px;
	    letter-spacing: 2px;
	}
	.hero-menu {
	    height: 70px;
	    &:before {
	    	bottom: 8px;
	    }
	}
	.hero-sec {
	    padding-bottom: 40px;
	}
	.btn-def {
	    font-size: 17px;
	        height: 46px;
    line-height: 46px;
    letter-spacing: 2px;
	}
	.store {font-size: 12px; letter-spacing: 1px;
		img {
			margin-right: 5px;
		}
	}
	.btn-arrow {margin-right: 25px !important}

	.hero-sec>img {
	    min-height: 410px;
	}
	.intro-sec .intro-txt {padding-right: 0}
	.amenities_txt p ,
	#wrapper {font-size: 15px;}
	.review .review_text .qoute_img {
		height: auto;
	}
	.qoute_img img {
		max-width: 68px;
	}
	.review .review_text {
		background-size: auto 78%;
	}
	.review .review_text h6 {
		font-size: 16px;
	}
	.review .review_text {margin: 40px auto}
	.btn_sty .cli-arrow , 
	 .green-pattern .cli-arrow  ,
	.adventure_sec .green-pattern .cli-arrow {
    	min-width: 230px;	
		height: 52px;
		font-size: 18px;
		padding-bottom: 5px;
		bottom: -26px;
	}
	.btn_sty .cli-arrow ,  {
		top: -26px;
		font-size: 16px;
		padding:  0 15px 5px;
	}
	.green-pattern .cli-arrow{top: -26px;} 
	.hero-sec .hero-text {padding-top: 20px;}
	.review .review_text p {
	    font-size: 19px;
	}
	.hero-sec.hero-in h1 {
	    font-size: 34px;
	}
	.hero-sec.hero-in img {
	    min-height: 340px;
	}
 	.hero-sec.hero-in h6 {
	    font-size: 17px;
	}
	.summer-sec {padding-top: 60px;}
	.summer-sec p {padding: 20px 0}
	.inner-intro {padding-bottom: 45px;}

	.history-intro {
	    padding: 50px 0 25px;
	}
	.leader-sec .leader_items .h4, .leader-sec .leader_items h4 {
	    font-size: 24px;
	}
	.grid-wrap .items {
	    h3{
    	    font-size: 15px;
    	}
    }
    .groupgrid{
    	+ .wood_bg {
    		display: none;
    	}
    }
    .amenities_txt .amenities_head:before ,
    .amenities_txt .amenities_head:after {
    	display: none;
    }
    .amenities_row .amenities_txt {padding-bottom: 30px;}
    .review .review_text {
	    min-height: 210px;
	}
	.review {padding: 55px 0;}
	.adventure_sec .green-pattern {
	    height: 60px;
	}
	.footer {padding-bottom: 25px;}
	.leader-sec .leader_items .cli-arrow {min-width: inherit; padding-bottom: 2px}
	.dotted_line{
		&:after ,
		&:before{
			display: none;
		}
	}
	.logo img {
	    max-width: 120px;
        margin-top: -14px;
	}
	.main-header ul.main-menu, .main-menu {padding-top: 25px;}
	.intro-sec .container {
	    padding-top: 35px;
	}
	.inner-intro {
        padding-bottom: 50px;
    }
    .btn {padding: 8px 20px;}
}

@media(max-width:360px) { 
	.logo img {
	    max-width: 100px;
	}
	.donate-btn {font-size: 13px;}

	.review .review_text {
    	min-height: 215px;
	}
	.review .review_text p {
	    font-size: 17px;
	}
	.review .review_text h6 {
		font-size: 14px;
	}
	.grid-wrap .items h3 { 
		font-size: 18px;
		min-width: 200px;
	}
}