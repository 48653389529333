/*
.adventure_sec_img {
	height: 780px;
}

*/.adventure_sec .green-pattern{
	height: 130px;
	z-index: 6;
}
.cli-arrow{white-space: nowrap;}
.adventure_sec {
	.green-pattern .cli-arrow {
		min-width: 625px; text-align: center;
	}
}
.green-pattern{
	background: #2c5c3e;	
	background-image: url('../images/pattern.png');
	background-repeat: repeat;	
	.cli-arrow{
		bottom: -55px;
		padding: 0 35px;
		position: absolute;
		left: 50%;
		background-color: #FF7F50;
		transform: translateX(-50%);
		height: 110px;
		font-size: 57px;
		font-family: $secondary-font-serif ;
		font-weight: 600;
	    line-height: normal;
		&:after ,
		&:before{
			background-color: #FF7F50;
		    width: 24px;
			right: -23px;
		}
		&:before {
			left: -23px;
		}
	}
}

.btn_sty {
	position: relative;
	.cli-arrow{
		top: -55px;
		padding: 0 35px;
		position: absolute;
		left: 50%;
		background-color: #FF7F50;
		transform: translateX(-50%);
		height: 110px;
		font-size: 57px;
		font-family: $secondary-font-serif ;
		font-weight: 600;
	    line-height: normal;
	    min-width: 896px;
		&:after ,
		&:before{
			background-color: #FF7F50;
		    width: 24px;
			right: -23px;
		}
		&:before {
			left: -23px;
		}
	}
}
.review{
	padding: 75px 0 85px;
	.review_text{
		max-width: 1040px;
		margin: 0 auto;
		background-image: url('../images/overlay-logo.png');
		background-position: center ;
		background-repeat: no-repeat;
		margin: 70px auto;
		min-height: 330px;
		p {
			font-family: $apple;
			font-size: 42px;
			line-height: 1.75;
			padding-bottom: 25px;
			margin: 0;
		}
		h6 {
			font-size: 20px;
			font-weight: bold;
			color: #815E32;
			margin: 0;
			letter-spacing: 1px;
		}

		.qoute_img {
			width: 100%;
			display: block;
		    height: 78px;
		    position: absolute;
		    left: 0;
		    top: -80px;
		    &:before{
		    	content: '';
		    	height: 2px;
		    	width: 100%;
		    	position: absolute;
		    	left: 0;
		    	top: 50%;
		    	margin-top: -1px;
		    	border-bottom: 2px dashed #87897e;
		    }
		    span {
		    	background-color: $body-bg;
		    	display: inline-block;
		    	padding: 10px 15px;
		    	position: relative;
		    }
		    &.qoute_img2{ 
		    	bottom: -70px;
		    	top: auto;
		     }
		}
	}
}

.amenities_row {
	img {
		min-height: 600px;
	}
	background-color: #f7f7f7;
	.container{
		left: 0; right:0;
		margin: 0 auto;
		top: 0;
		height: 100%;
	}
	&:nth-child(even) {
		background-color: #f7f7f7;
		.amenities_txt{
			right: 15px;
			left: auto;
		}
	}
}
.amenities_txt {
	width: 416px;
	padding: 35px;
	bottom: 75px;
	left: 15px;
	background-color: rgba(255,255,255,.9);
	h3 {
		font-family: $secondary-font-serif;
		font-weight: 600;
		text-transform: uppercase;
		margin: 0;
		line-height: normal;
		color: #fff;
		position: relative;
		padding-bottom: 8px;
		&:before{
			content: '';
			position: absolute;
			left: -38px;
			width: 38px;
			background-color: #1a5632;
			top: 0;
			height: 100%;
		}
		&:after {
			content: '';
			position: absolute;
			background-color: #143722;
			width: 33px;
			height:16px;
			top: -15px;
			left: -38px;
			clip-path: polygon(100% 0, 0% 100%, 100% 100%);
		}
	}
	.amenities_head{
		background-color: #1a5632;
		margin-bottom: 18px;		
		margin-left: -30px;
		position: relative;
		 max-width: 100%;
		&:before{
			content: "";
		    position: absolute;
		    width: 16px;
		    top: 0;
		    height: 100%;
	        right: -15px;

	        background-color: #1a5632;
			clip-path: polygon(0 0,100% 0,10% 50%,100% 100%,0 100%);
		}
		&:after{
			content: "";
		    position: absolute;
		    width: 24px;
		    top: 0;
		    height: 100%;
	        right: -23px;
	        background-color: coral;
   	        clip-path: polygon(100% 0,40% 51%,100% 100%,60% 100%,0 50%,60% 0);

		}
	}
	p{
		margin-bottom: 22px;
		font-size: $px17;
		font-weight: 300;
		line-height: 1.65;
	}
}

.intro-sec{
	background-image: url('../images/intro-bg.jpg');
	background-size: contain;
	background-repeat: no-repeat;
	background-color: #ece7e1;
	background-position: bottom center;
	position: relative;
	&:before{
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		background-color: #FF7F50;
		width: 100%;
		height: 30px;
	}
	.container{	
		padding-top: 55px;
		padding-bottom: 85px;
	}
	.intro-img {
		position: absolute;
		right: 0;
		bottom: 0;
		max-width: 50%;
	}
	.intro-txt {
		padding-right: 85px;
	}
}

.inner-intro{
	padding: 45px 0 90px;
	:last-child{
		margin-bottom: 0;
	}
	p {
		margin-bottom: 30px;
	}
}

.star_bg {
    background-image: url('../images/star.png');
    background-size: inherit;background-repeat: repeat;
    background-color: #c3b9ac;
    &:before{display: none}
}

.summer-sec{
	padding: 150px 0 95px;
	color: #fff;
	p {
		max-width: 720px;
		margin: 0 auto;
		padding: 40px 0 35px;
	}
	.cli-arrow{
		top: -55px; bottom: auto;
	}
}

.grid-boxes{
	background-color: #ebe6e0;
}

.grid-wrap {
    padding-top: 95px;
    padding-bottom: 0;
    margin-left: -45px;
    margin-right: -45px;
    .items{
        max-width: 50%;
        width: 50%;
        padding-left: 45px;
        padding-right: 45px;
        padding-bottom: 90px;
        position: relative;
        .item-img {
            margin-bottom: 70px;
            display: inline-block;
            font-size: 0;
            position: relative;
            z-index: 1;
            
            &:before{
                content: '';
                background-color: #FF7F50;
                position: absolute;
                width: 100%;
                height: 100%;
                left: -20px;
                top: -20px;
                z-index: -1;
            }
        }
        h3 {
            letter-spacing: 0   ;
            margin-bottom: 0;
            padding: 0 20px 2px;
            min-width: 350px;
        }
        .btn-arrow {
            margin-bottom: 15px;
            &:before{
                width: 22px;
                left: -21px;
                clip-path: polygon(50% 0,100% 50%,50% 100%,0 100%,50% 50%,0 0);
            }
            &:after {
                display: none;
            }
        }

        &:nth-child(even) {
           .item-img {
                &:before{
                    left: 20px;
                    top: 20px;
                }
           }
        }
        .items_in {position: relative; max-width: 545px;}
        .items_in:after{
        	content: '';
        	position: absolute;
        	left: 0;
        	width: 100%;
        	height: 2px;
        	border-bottom: 2px dashed #b19e88;
        	top: -50px;

        }
       	&:nth-child(1) ,&:nth-child(2) {
       		.items_in:after {
       			display: none;
       		}
       	}
    }
}

.groupgrid {
	.grid-wrap {
		.items {
			.item-img{
				margin-bottom: 45px;
			}
			&:nth-child(even) {
				.items_in{
					.item-img{
						order: 2;
						margin-bottom: 0;
						margin-top: 40px;
					}
					.item-txt{
						order: 1;
						width: 100%;
						max-width: 100%;
					}
				}
			}
		}
	}
}

.wood_bg {
	background-image: url('../images/f_bg.jpg');
	height: 120px;background-size: cover;
}


.overlay_img {
	position: absolute;
	right: -25px;
	top: 318px;
	max-width: 60%;
	&.overlay_img2{
		top: 1800px;
		right: 30px;
	}
}
.bg_light_yellow {
	background-color: #ebe6e0;
}
.history-intro{
	padding: 85px 0 45px;
	
}
.para_2 {
	p {
		max-width: 50%;
		width: 50%;
		padding: 0 20px;
	}
}

.staff-leadership{
	color: #fff;
	padding: 65px 0 160px;
	h2 {
		margin-bottom: 60px;
	}
}
.hero-sec.hero-in h1 {margin-bottom: 0 !important}

.leader_img  {
	img{
		object-fit: contain
	}
}

.staff-leadership {
	padding-bottom: 15px;
	margin-bottom: 0;
	h3 {
		font-size: 57px;
		margin-bottom: 62px;
	}
}

.leader-sec{
	margin: 0 -15px;
	.leader_items{
		max-width: 570px;
		width: 50%;
		padding: 0 15px 76px;
		.leader_img {
			margin-bottom: 28px;
		}
		
		h4 {
			color: #fff;
			margin-bottom: 10px;
			font-size: 39px;
			line-height: 1.25;
		}
		.cli-arrow{
			height: 52px;font-size: 24px;
			padding-top: 2px;
		   	right: 0;
	    	left: 0;
	    	top: auto;
	    	bottom: auto;
	    	margin: 0 auto;
	    	transform: none;
	    	min-width: 330px;
		}
		.designation {
			margin-bottom: 40px;
		}
		&.w-100 {
			max-width: 100%;
			.leader_items {
				padding-bottom: 0;
			}
		}
	}
}


.dotted_line{
	position: relative;
	&:after ,
	&:before{
		content: '';
		position: absolute;
		left: 0;
		width: 30%;
		height: 2px;
		border-bottom: 2px dashed #fff;
		top: 62%;
	}
	&:after{
		right:0;
		left: auto;
	}
}

.store a , .footer .f_left ul li a ,
.main-header ul li a {color: inherit;}
.main-header ul li.home-logo {font-size: 0}
.donate-btn {color: #fff}
.social-icon {display: flex; align-items: center;}
.social-icon li + li {
	    margin-left: 16px;
}
.social-icon li a {color: rgba(255,255,255,.8); font-size: 30px;}





.search-form>label {
    width: calc(100% - 80px);
    max-width: calc(100% - 80px);
}
.search-form {
    padding-top: 25px;
    display: flex;
    justify-content: space-between;
    max-width: 300px;
}
.search-form>label input {
    border-right: 0;
    width: 100%;
    background: #fff!important;
    padding: 0 10px;
    color: #000;
}
.search-form input {
    height: 42px;
    border: 1px solid coral;
    background-color: coral !important;
    color: #fff;
    outline: 0;
    width: 80px;
}
.search-no-results h2 {font-size: 2.4rem}
.entry-header{margin-bottom: 30px;}

.main-header ul li a:hover ,
.hero-menu ul li a:hover ,
.social-icon li a:hover ,
.footer .f_left ul li a:hover {
    color: coral
}
.post-meta {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    color: #000;
}
.post-meta li {padding: 0 10px;
    display: flex;
    align-items: center;
    color: #000;
}
.post-meta .meta-text a {
    color: inherit;
}
.article {padding-bottom: 35px}
.meta-icon{margin-right: 8px;}
.inner-intro .grid-sec .grid-box{ padding-bottom:40px; }
.about-template  .hero-menu ul li a {color: #fff !important}
.about-template  .hero-menu ul li a:hover {color: coral!important}


.sec_abs{
    position: absolute;
    left: 0;
    top: -170px;
    width: 1px; height: 1px;
}

html {
    scroll-behavior: inherit !important;
}
 .leader-sec .leader_items h4  {
    font-family: "sweet-sans-pro", sans-serif;;
        font-weight: bold;
 }
 .intro-sec .intro-img {width: 50%}
 .slick-slide {font-size: 0}
 .hero-menu {padding-top: 0}
 .hero-sec.hero-in h1  {font-weight: 600}