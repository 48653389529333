.footer{
	background-image: url('../images/f_bg.jpg');
	background-size: cover;
	padding: 35px 0;
	color: #fff;
	p {
		margin: 0;
	}
	.f_left{
		font-weight: 600;
		font-size: 17px;
		line-height: 1.95;
		p {
			margin-right: 50px;
		}
		ul {
			margin: 0 -18px;
			li {
				position: relative;
				padding: 0 18px;
				+ li:before {
					content: '';
					height: 20px;
					left: -1px;
					width: 2px;
					position: absolute;
					background-color: #FF7F50;
					top: 50%;
					margin-top: -8px;
				}
			}
		}
	}
}

.social-icon {
	a {
		display: inline-block;
		+ a {
			margin-left: 16px;
		}
	}
}
.silver-cliff-ico{
	width: 128px;
	position: relative;
	margin-left: 50px;
	img{
	    position: absolute;
    	top: -94px;
    	left: 0;
    	max-width: 100%;
	}
}
