
* {box-sizing: border-box;}
strong {
   font-weight: 800;
}

img {
    max-width: 100%;
}
a {
    transition: all .3s;
    text-decoration: none;
}
ul {
    padding: 0;
    margin: 0;
    li {
        list-style: none;
    }
}
.container-fluid { 
    padding-left: 30px;
    padding-right: 30px;
}
body {
    font-size: $px16;
    overflow-x: hidden;
    line-height: 1.65;
}
h1{font-size: $h1-font-size; line-height: 1.1}
h2{font-size: $h2-font-size; line-height: 1.1}
h3{font-size: $h3-font-size; font-weight: bold; text-transform: capitalize;}
h4{font-size: $h4-font-size; line-height: 1.45; color: #1A5632}
h5{font-size: $h5-font-size; line-height: 1.2;}
h6{font-size: $h6-font-size; line-height: 1.3;}
a {
    color: inherit;
}
#wrapper {
    font-size: $px17;
}
.header {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 123;
}
.form-control:focus ,
.form-control{
    background: #fff;
    height: 52px;
    border: 2px solid #ccd0cd;
    color: #000;
    outline: none;
    box-shadow: none;
    font-size: $px15;
    border-radius: 0;
    padding: 0 25px;
}
.form-group{
    margin-bottom: 22px;
}
.btn {
    background-color: #FF7F50;
    color: #000;
    text-transform: uppercase;
    height: 46px;
    min-width: 200px;
    border-radius: 16px;
    font-size: 22px;
    padding: 9px 30px;
    line-height: 1;
    font-weight: 600;
    font-family: $secondary-font-serif;
    transition: all .4s;
    &:hover {
        background: #000;
        color: #FF7F50;
    }
    &.more_btn {

    }
}

.top-header {
    background: rgba(129,94,50,.95);
    height: 78px;
    z-index: 2;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        bottom: 8px;
        height: 2px;
        width: 100%;
        background: #fff;
    }
}
.store{
    font-family: $poppins;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.65;
    letter-spacing: 3px;
    color: #fff;
    text-transform: uppercase;
    img {
        margin-right: 10px;
    }
}

.main-header{
    background-color: rgba(214,209,202,9);
    z-index: 4;
    padding-right: 15px;
    ul{
        margin: 0 -16px;
        li {
            height: 62px;
            display: flex;
            align-items: center;
            padding: 0 18px;
            font-weight: bold;
            font-size: 15px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}

.donate-btn{
    background: #FF7F50;
    height: 34px;
    line-height: 34px;
    padding: 0 10px;
    letter-spacing: 1px;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 15px;
    position: relative;
    display: inline-block;
    
}

.cli-arrow{
    position: relative;
    display: inline-block;
    background: #ff7f50;
    &:after ,
    &:before{
        content: '';
        position: absolute;
        left: -14px;
        top: 0;
        clip-path: polygon(50% 0, 100% 0%, 100% 100%, 50% 100%, 100% 50%);
        clip-path: polygon(50% 0, 100% 0%, 100% 100%, 49% 100%, 90% 50%);

        width: 15px;
        height: 100%;
        background: #FF7F50;
    }
    &:after{
        left: auto;
        right: -14px;
        clip-path: polygon(0% 0%, 50% 0, 10% 50%, 50% 100%, 0% 100%);
    }
}
.btn-arrow {
    position: relative;
     &:after ,
    &:before{
        background: #FF7F50;
        content: '';
        position: absolute;
        width: 12px;
        height: 100%;
        left: -15px;
        clip-path: polygon(40% 0, 100% 50%, 40% 100%, 0% 100%, 60% 50%, 0 0);
    }
    &:after {
        right: -15px;
        left:auto;
        clip-path: polygon(100% 0%, 40% 51%, 100% 100%, 60% 100%, 0% 50%, 60% 0);
    }
}



.btn-def {
    background: #1A5632;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 900;
    color: #fff;
    font-family: $font-family-sans-serif;
    height: 54px;
    line-height: 54px;
    padding: 0 16px;
    letter-spacing: 3px;
    white-space: nowrap;
    &:after ,
    &:before{
        background: #1A5632;
        width: 12px;
        right: -11px;
    }
    &:before{
        left: -11px;
        clip-path: polygon(0 0,100% 0,100% 100%,0% 100%,90% 50%);
    }
    &:after{clip-path: polygon(0 0,100% 0,10% 50%,100% 100%,0 100%);}
}


.hero-sec{
    > img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: bottom center;
        max-height: 920px;
    }
    padding-bottom: 80px;
    .hero-text{
        text-transform: uppercase;
        h1{font-weight: 400; text-transform: uppercase; margin-bottom: 30px;}
        top: 50%;
        transform: translateY(-50%) ;
        color: $white;
        p {
            font-size: 32px;
            line-height: normal;
            font-family: $font-family-sans-serif;
            font-weight: 900;
            letter-spacing: 4px;
            text-transform: uppercase;
        }
        .cli-arrow{
            
        }
    }
    &.hero-in {
        h6 {
            font-size: 28px;
            line-height: 1.25;
            margin: 0;
            letter-spacing: 1px;
            font-weight: 700;
            text-transform: uppercase;
            font-family: $font-family-sans-serif;
        } 
        h1{font-size: 105px;}
        img {
            object-position: top center;    
        }
    }
}
.hero-menu {
    padding-top: 5px;
    height: 130px;
    width: 100%;
    background-size: contain;
    background-repeat: repeat-x;
    background-position: center;
    position: absolute;
    bottom: 0;
    &:before{
       content: '';
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 18px;
        margin-top: -1px;
        border-bottom: 2px dashed #fff;
    }
    ul {
        li {
            padding: 0 24px;
            font-size: 15px;
            line-height: normal;
            letter-spacing: 2px;
            font-weight: 700;
            text-transform: uppercase;
            color: #fff;
            position: relative;
            a {
                color: inherit;
            }
            + li {
                &:before{
                    content: "";
                    height: 20px;
                    left: -1px;
                    width: 2px;
                    position: absolute;
                    background-color: coral;
                    top: 50%;
                    margin-top: -8px;
                }
            }
        }
    }
}

.since {
    &:after,
    &:before{
        width: 600px;
        max-width: 100%;
        height: 2px;
        background: rgba(255,255,255,.6);
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 40%;
        z-index: -1;
    }
    &:after {
        top: auto;
        bottom: 40%;
    }
}


.img-cover{
    width: 100%;
    font-size: 0;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

body {

    &:not(.home) {
        .hero-sec{
            &:before{
                background-color: rgba(129,94,50,.4);
                content: '';
                left: 0;
                top: 0;
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.current-menu-item {
    a {
        color: #FF7F50 !important;
    }
}




header.header.sticky {
    position: fixed;
    .top-header{
        display: none;
    }
}