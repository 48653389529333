

/* START :: Custom variable */
html {
    overflow-x: hidden;
}
img {max-width: 100%;}

/* START :: Bootstrap variable  */
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1250px,
  xxl: 1280px
);

$px32 : 2rem;
$px30 : 1.875rem;
$px28 : 1.75rem;
$px27 : 1.688rem;
$px26 : 1.625rem;
$px24 : 1.5rem;
$px22 : 1.375rem;
$px21 : 1.313rem;
$px20 : 1.25rem;
$px18 : 1.125rem;
$px17 : 1.063rem;
$px16 : 1rem;
$px15 : 0.938rem;
$px14 : 0.875rem;
$px13 : 0.813rem;
$px12 : 0.75rem;
$px11 : 0.688rem;
$px10 : 0.625rem;

$body-color: #000000 !default;
$body-bg: #f7f7f6 !default;

$primary: #000000;
$secondary:  #ffffff !default;
$gray_color : #464938;
$line-height-base: 1.35;  

$font-size-root: 16px;

$font-family-sans-serif : 'sweet-sans-pro', sans-serif;
$secondary-font-serif : 'obviously-narrow', sans-serif;
$poppins : 'Poppins';
$apple :  'Homemade Apple';

$h1-font-size: 115px;
$h2-font-size: 68px; 
$h3-font-size: 34px;
$h4-font-size: 30px;;
$h5-font-size: 21px;
$h6-font-size: $px13;

$headings-font-family :  $secondary-font-serif ;
$headings-font-style: normal;
$headings-line-height: 1;

$container-padding-x: 15px !default;
.container-lg{
    max-width : 1370px !important;
}
.container-sm {
    max-width : 1080px !important;
}
 
/*END :: Bootstrap variable*/






.container-fluid {
    max-width: 1530px;
}